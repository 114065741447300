interface MaximizeItem {
  title: string;
  link: string;
}

export interface ObjectivesItem {
  number: number,
  text: string,
}

interface ControlItem {
  title: string,
  subheader: string,
  text: string[],
}

interface PredictabilityItem {
  tagline: string,
  title: string,
  text: string,
}

export const maximize: MaximizeItem[] = [
  {
    title: "Predictability of Income in Future Years",
    link: "#pcm-strategy__predictability",
  },
  {
    title: "Peace of mind",
    link: "#pcm-strategy__peace",
  },
  {
    title: "Keeping Pace with Inflation",
    link: "#pcm-strategy__keeping-pace",
  },
  {
    title: "Control and Flexibility",
    link: "#pcm-strategy__control",
  },
];

export const accumulators: string[] = [
  "Retirees care about the sequence over time of positive and negative returns because they are withdrawing money from their portfolio reducing the amount of capital at work when markets bounce back from a decline. Younger investors are indifferent to the sequence of returns.",
  "Retirees tend to evaluate risk over a shorter investment horizon because their age, and sometimes health, makes it extra challenging for them to know whether their remaining life is long (30+ years)or short (3 years).",
  "Because they are past their peak earning years and don’t necessarily have 40 years of saving and compounding to rebuild their current level of retirement savings, when faced with a market downturn, retirees are more prone to locking in losses to protect their remaining savings.",
];

export const objectives: ObjectivesItem[] = [
  {
    number: 6,
    text: "annually would be the set rate for the annual distribution, payable as 1.5% of Net Asset Value each quarter.",
  },
  {
    number: 9,
    text: "would be the minimum average annual return, or it must be at least 2% higher than that of a 60/40 stock/bond index portfolio.",
  },
  {
    number: 80,
    text: "or more of the portfolio should be capable of being converted to cash within two days' notice.",
  },
  {
    number: 16,
    text: "would be the maximum decline in value over a half-year period.",
  },
];

export const construction: ObjectivesItem[] = [
  {
    number: 1.5,
    text: "of the account value will be transferred automatically by the manager quarterly to a client-designated checking account, regardless of dividends, coupons, and premium income (unless the client has elected to receive a lower percentage distribution).",
  },
  {
    number: 25,
    text: "of client capital may be borrowed by accounts to increase market exposure and magnify returns.",
  },
  {
    number: 20,
    text: "is the maximum portion of the portfolio that may be invested in limited liquidity instruments, such as the Cliffwater Corporate Lending Fund.",
  },
  {
    number: 15,
    text: "or less should be the cap on individual strategies potential losses in an account over six months, ensured by purchasing put options on the full ETF exposure at a specified strike price; the portfolio is diversified across assets and strategies, ensuring that at least one strategy is well-suited to any challenging market environment.",
  },
];

export const predictability: PredictabilityItem[] = [
  {
    tagline: "Invest with Confidence",
    title: "Predictability of Income",
    text: "With one year Treasury Notes and three year Bank CDs paying 5%, the need for a more complicated income strategy may not be as obvious as when yields on such instruments are low, but when the CD or Treasury note matures, investors will need to reinvest the maturing principal at whatever the prevailing rate is at the time which may be much lower than rates available today.",
  },
  {
    tagline:
      "Stability in Volatility: Safeguarding Your Retirement Against Market Uncertainty",
    title: "Peace of mind",
    text: "Certain high yielding investments such as Master Limited Partnerships (MLPs) and Real Estate Investment Trusts (REITs) and pure covered call strategies such as QYLD can offer high yields but also come with the potential for steep declines in value, large enough to cause many investors to feel they need to sell their holding in order to avoid further damageretirement savingsThese declines tend to coincide with large downturns in the stock market when the media is highlighting losses and speculating that further losses may be expected.As a result, these declines in value can at best create anxiety for retired investors who don’t want to be worrying about their financial security or about when to sell and when they should be njoying themselves and their families and at worst can prompt nervous investors to liquidate their investments, locking in losses and remaining in cashed until the prices have returned to their previous, virtually guaranteeing that their retirement savings will never recover.",
  },
];

export const keepingPace: ControlItem = {
  title: "Keeping Pace With Inflation",
  subheader: "Strategies for Retirement Savings in an Inflationary Environment",
  text: [
    "Historically, inflation has averaged between 2-3% annually over the long term. This means that for retirement savings to maintain the same purchasing power in the future, they must grow between 2-3% faster than whatever the yield on any bonds an investor might buy.",
    "At maturity, bonds usually return the principal to the investor, meaning that after the retiree spends the bond coupon payments on living expenses, the purchasing power of the principal will have been reduced by 2-3% each year on average. Even if an investor can find a bond that pays a coupon equivalent of 5-6% annually, when it matures, it will not have the same purchasing power as the retiree’s initial nest egg.",
    "While dividend-paying stocks can provide investors with growth as well as income, the current dividend yield on the S&P500 is less than 2%.",
  ],
};

export const control: ControlItem = {
  title: "Control and flexibility",
  subheader:
    "Choosing a Path to Retirement Income: Fixed Index Annuities vs. PCM's Approach",
  text: [
    "Fixed index annuities are a popular solution for income in retirement. Insurers offer a premium yield but invest the assets in equities, real estate and other investments that over the long term will have a higher return than the yield offered to annuity purchasers.",
    "The disadvantage of annuities is that once purchased, what you own is a guaranteed stream of payments that will only last as long as you live or for the life of the annuity contract. The money paid to the insurance company now belongs to the issuer and is unavailable to the retiree should an unexpected expense arise. Similarly, if the retiree dies, neither the stream of payments nor the cost of the annuity is available to their heirs. Retirees do not want to be forced to choose between their own standard of living and the legacy they leave behind for their family. In contrast the market value of the PCM Income + Growth Account is always available to the investor or their heirs and therefore offers significantly more control and flexibility than a fixed index annuity contract.",
    "While riders can be purchased with annuities to add an inflation or life insurance benefit, the rider usually reduces the yield available on the annuity dramatically.",
  ],
};

export const flagship: string =
  "Preservation Capital Management introduces its flagship multi-strategy Income with Growth Account designed to balance the multi-dimensional concerns of investors in or nearing retirement, recogniing thein unique needs and challenges of investors in retirement."
