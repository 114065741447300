import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./maximize.scss";
import { maximize } from "../../../data/pages/strategy";
import { Link } from "../../links";
import CTA from "../../Button";
gsap.registerPlugin(ScrollTrigger);

const Maximize: React.FC = () => {
  const timelineLineRef = useRef<HTMLSpanElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const headingArrowRef = useRef<HTMLDivElement>(null);
  const headerTextRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (headerTextRef.current) {
      const subHeaderWords = [...headerTextRef.current.children];
      subHeaderWords.forEach((word, index) => {
        gsap.fromTo(
          word.children[0],
          {
            y: 50,
            duration: 0.75,
            delay: 0.12 + index * 0.015,
            ease: "power1.out",
          },
          {
            y: 0,
            ease: "power1.out",
            duration: 0.75,
            delay: 0.12 + index * 0.015,
            scrollTrigger: {
              trigger: headerTextRef.current,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
      });
    }
    if (headingArrowRef.current) {
      gsap.fromTo(
        headingArrowRef.current,
        {
          x: -100,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 0.75,
          ease: "power1.out",
          duration: 0.75,
          delay: 0.12,
          scrollTrigger: {
            trigger: headingArrowRef.current,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }

    gsap.fromTo(
      timelineLineRef.current,
      { height: 0 },
      {
        duration: 1,
        ease: "power1.out",
        height: "100%",
        scrollTrigger: {
          trigger: timelineRef.current,
          start: "top bottom",
          end: "bottom top",
        },
      }
    );

    const contentCards = [...(contentRef.current?.children || [])];

    contentCards.forEach((card: Element, index: number) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: card,
          start: "top bottom",
          end: "bottom top",
          toggleActions: "play none none none",
        },
      });

      const horizontalLine = card.querySelectorAll(
        ".pcm-maximize__timeline__card__line"
      );

      tl.fromTo(
        horizontalLine,
        { width: 0 },
        { width: `${35 - index * 8}%`, duration: 1, ease: "power1.out" }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const maximizeText =
    "Maximizing investment income is important, but should be balanced with other concerns of retirees:";
  return (
    <section id="pcm-maximize" className="pcm-maximize">
      <div className="pcm-maximize__inner">
        <div className="pcm-maximize__header">
          <h5>Discover</h5>
          <h2 ref={headerTextRef}>
            {maximizeText.split(" ").map((word, index) => (
              <span key={word+index}>
                <span>{`${word} `}</span>{" "}
              </span>
            ))}
          </h2>
          <div ref={headingArrowRef} className="pcm-maximize__header__arrow">
            <h4>Click to navigate</h4>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="var(--white)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 12l-6-6m6 6l-6 6m6-6H5"
              />
            </svg>
          </div>
        </div>
        <div ref={timelineRef} className="pcm-maximize__timeline">
          <span
            className="pcm-maximize__timeline__line"
            ref={timelineLineRef}
          ></span>
          <div ref={contentRef} className="pcm-maximize__timeline__content">
            {maximize.map((card) => {
              const { title, link } = card;
              return (
                <div key={title} className="pcm-maximize__timeline__card">
                  <span className="pcm-maximize__timeline__card__line"></span>
                  <Link
                    to={link}
                    className="pcm-maximize__timeline__card__link"
                  >
                    <p>{title}</p>
                    <span className="pcm-maximize__timeline__card__transition"></span>
                    <div className="pcm-maximize__timeline__card__arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="none"
                          stroke="var(--white)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 12l-6-6m6 6l-6 6m6-6H5"
                        />
                        <path
                          fill="none"
                          stroke="var(--white)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 12l-6-6m6 6l-6 6m6-6H5"
                        />
                      </svg>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Maximize;
