import React, { useRef, useEffect, useState } from "react";
import "./objectives.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Counter from "./counter";
import { ObjectivesItem } from "../../../data/pages/strategy";
gsap.registerPlugin(ScrollTrigger);

interface ObjectivesProps {
  data: ObjectivesItem[];
  id: number,
}

const Objectives: React.FC<ObjectivesProps> = ({ data, id }) => {
  const [startCounts, setStartCounts] = useState<boolean[]>(
    new Array(data.length).fill(false)
  );

  const objectivesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    data.forEach((_, index) => {
      ScrollTrigger.create({
        trigger: `#objective-${index*id}`,
        start: "top bottom",
        onEnter: () =>
          setStartCounts((prev) => {
            const newStartCounts = [...prev];
            newStartCounts[index*id] = true;
            return newStartCounts;
          }),
      });
    });

    if (objectivesRef.current) {
      gsap.utils.toArray<HTMLDivElement>(".pcm-objectives__card").forEach((card) => {
        const triggerElement = card.closest(".pcm-objectives__card") as HTMLElement;
        gsap.fromTo(
          card,
          {
            opacity: 0,
            y: 100,
          },
          {
            opacity: 1,
            y: 0,
            duration: 0.75,
            ease: "power1.out",
            scrollTrigger: {
              trigger: triggerElement,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
      })
      gsap.utils
        .toArray<HTMLParagraphElement>(".pcm-objectives__card p")
        .forEach((paragraph) => {
          const triggerElement = paragraph.closest(
            ".pcm-objectives__card"
          ) as HTMLElement;
          gsap.fromTo(
            paragraph.querySelectorAll("span"),
            { y: 20, opacity: 0 },
            {
              y: 0,
              opacity: 1,
              duration: 0.5,
              ease: "power1.out",
              stagger: 0.015,
              scrollTrigger: {
                trigger: triggerElement,
                start: "top bottom",
                end: "bottom top",
              },
            }
          );
        });
    }
  }, [data]);

  return (
    <div ref={objectivesRef} className="pcm-objectives">
      {data.map((card, index) => {
        const { number, text } = card;
        return (
          <div
            key={`card-${index*id}`}
            id={`objective-${index*id}`}
            className="pcm-objectives__card"
          >
            <Counter startCount={startCounts[index*id]} target={number} />
            <p>
              {text.split(" ").map((word, index) => (
                <span key={word+index}>
                  <span>{`${word} `}</span>{" "}
                </span>
              ))}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Objectives;
