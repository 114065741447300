import React, { useRef, useEffect } from "react";
import "../styles/pages/strategy.scss";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { Maximize, Objectives } from "../components/strategy";
import gsap from "gsap";
import {
  maximize,
  accumulators,
  objectives,
  construction,
  predictability,
  keepingPace,
  control,
  flagship,
} from "../data/pages/strategy";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CTA from "../components/Button";
gsap.registerPlugin(ScrollTrigger);

const Strategy: React.FC = () => {
  const heroImageRef = useRef<HTMLDivElement>(null);
  const accumulatorsImageRef = useRef<HTMLDivElement>(null);
  const multiStrategyRef = useRef<HTMLDivElement>(null);
  const singleStrategyRef = useRef<HTMLDivElement>(null);
  const subHeaderRef = useRef<HTMLParagraphElement>(null);
  const objectivesSubheaderRef = useRef<HTMLParagraphElement>(null);
  const objectivesHeaderRef = useRef<HTMLParagraphElement>(null);
  const constructionSubheaderRef = useRef<HTMLParagraphElement>(null);
  const constructionHeaderRef = useRef<HTMLParagraphElement>(null);
  const strategyPageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (strategyPageRef.current) {
      const leftTextElements = Array.from(
        strategyPageRef.current.querySelectorAll(".pcm-anim-left")
      );
      const rightTextElements = Array.from(
        strategyPageRef.current.querySelectorAll(".pcm-anim-right")
      );

      leftTextElements.forEach((ele) => {
        gsap.fromTo(
          ele,
          {
            x: -150,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            duration: 1,
            ease: "power1.out",
            scrollTrigger: {
              trigger: ele,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
      });
      rightTextElements.forEach((ele) => {
        gsap.fromTo(
          ele,
          {
            x: 150,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            duration: 0.75,
            ease: "power1.out",
            scrollTrigger: {
              trigger: ele,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
      });
    }

    if (heroImageRef.current) {
      gsap.fromTo(
        heroImageRef.current,
        { y: -300 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ".pcm-strategy__hero",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }

    if (subHeaderRef.current) {
      const subHeaderWords = [...subHeaderRef.current.children];
      subHeaderWords.forEach((word, index) => {
        gsap.fromTo(
          word.children[0],
          {
            y: 40,
            duration: 0.75,
            delay: 0.5 + index * 0.015,
            ease: "power1.out",
          },
          {
            y: 0,
            ease: "power1.out",
            duration: 0.75,
            delay: 0.5 + index * 0.015,
            scrollTrigger: {
              trigger: subHeaderRef.current,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
      });
    }
    if (objectivesSubheaderRef.current) {
      const objectivesSubheaderWords = [
        ...objectivesSubheaderRef.current.children,
      ];
      objectivesSubheaderWords.forEach((word, index) => {
        gsap.fromTo(
          word.children[0],
          {
            y: 40,
            duration: 0.75,
            delay: index * 0.015,
            ease: "power1.out",
          },
          {
            y: 0,
            ease: "power1.out",
            duration: 0.75,
            delay: index * 0.015,
            scrollTrigger: {
              trigger: objectivesSubheaderRef.current,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
      });
    }
    if (objectivesHeaderRef.current) {
      gsap.fromTo(
        objectivesHeaderRef.current,
        {
          x: -300,
          opacity: 0,
        },
        {
          opacity: 1,
          x: 0,
          duration: 0.75,
          ease: "power1.out",
          scrollTrigger: {
            trigger: objectivesHeaderRef.current,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
    if (constructionSubheaderRef.current) {
      const constructionSubheaderWords = [
        ...constructionSubheaderRef.current.children,
      ];
      constructionSubheaderWords.forEach((word, index) => {
        gsap.fromTo(
          word.children[0],
          {
            y: 40,
            duration: 0.75,
            delay: index * 0.015,
            ease: "power1.out",
          },
          {
            y: 0,
            ease: "power1.out",
            duration: 0.75,
            delay: index * 0.015,
            scrollTrigger: {
              trigger: constructionSubheaderRef.current,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
      });
    }
    if (constructionHeaderRef.current) {
      gsap.fromTo(
        constructionHeaderRef.current,
        {
          x: 300,
          opacity: 0,
        },
        {
          opacity: 1,
          x: 0,
          duration: 0.75,
          ease: "power1.out",
          scrollTrigger: {
            trigger: constructionHeaderRef.current,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }

    if (accumulatorsImageRef.current) {
      const realAccumulatorsImage: HTMLDivElement | null =
        accumulatorsImageRef.current?.querySelector("div") ?? null;
      gsap.fromTo(
        realAccumulatorsImage,
        { y: -120 },
        {
          y: 0,
          scrollTrigger: {
            trigger: "#pcm-strategy__accumulators",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }

    if (singleStrategyRef.current) {
      interface TriggerMap {
        [key: number]: string;
      }
      const triggerMap: TriggerMap = {
        0: "#pcm-strategy__predictability",
        1: "#pcm-strategy__peace",
      };
      const images = singleStrategyRef.current.querySelectorAll(
        ".pcm-strategy__single-text__image__wrapper > div"
      );

      images.forEach((image, index) => {
        gsap.fromTo(
          image,
          { y: -120 },
          {
            y: 0,
            scrollTrigger: {
              trigger: triggerMap[index],
              scrub: true,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
      });
    }
    if (multiStrategyRef.current) {
      interface TriggerMap {
        [key: number]: string;
      }
      const triggerMap: TriggerMap = {
        0: "#pcm-strategy__keeping-pace",
        1: "#pcm-strategy__control",
      };
      const images = multiStrategyRef.current.querySelectorAll(
        ".pcm-strategy__multi-text__image__wrapper > div"
      );

      images.forEach((image, index) => {
        gsap.fromTo(
          image,
          { y: -120 },
          {
            y: 0,
            scrollTrigger: {
              trigger: triggerMap[index],
              scrub: true,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
      });
    }
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  const objectivesSubheader =
    "Aspirational Investment Goals: Target Returns and Liquidity Requirements";
  const constructionSubheader =
    "Strategic Approaches to Portfolio Construction and Managements";
  return (
    <div ref={strategyPageRef} className="pcm-strategy">
      <section className="pcm-strategy__hero">
        <div ref={heroImageRef} className="pcm-strategy__hero__image-wrapper">
          <StaticImage
            src="../images/strategy/strategyHero.jpg"
            alt="hero background"
            layout="fullWidth"
            quality={80}
            formats={["auto", "webp", "avif"]}
            style={{ height: "100%", width: "100%" }}
            loading="eager"
          />
        </div>
        <div className="pcm-strategy__hero__inner">
          <div className="pcm-strategy__hero__text">
            <h1>Our Strategy: Multi-Strategy Income + Growth Accounts</h1>
            {/* <h2>Muti-Strategy Income + Growth</h2> */}
            <h2>Yield without the Trade-Offs</h2>
            <h3>
              Preservation Capital Management introduces its flagship
              multi-strategy Income with Growth Account designed to balance the
              multi-dimensional concerns of investors in or nearing retirement,
              recognizing the unique needs and challenges of investors in
              retirement.
            </h3>
          </div>
          <CTA theme="green" isAnchor to="#pcm-maximize">
            Learn more
          </CTA>
        </div>
      </section>
      <Maximize />
      <section
        id="pcm-strategy__accumulators"
        className="pcm-strategy__accumulators"
      >
        <div className="pcm-strategy__accumulators__inner">
          <div className="pcm-strategy__accumulators__text pcm-anim-left">
            <h5>Compare</h5>
            <h2>Accumulators vs. De-cumulators</h2>
            <p>
              Most Investment Solutions currently available do not distinguish
              between the needs of investors saving for retirement and investors
              in retirement.
            </p>
            <p>
              3 Important Considerations Differentiate the Investment Profile of
              Retirees:
            </p>
            <ul className="pcm-strategy__accumulators__text__body">
              {accumulators.map((bodyText) => {
                return <li key={bodyText}>{bodyText}</li>;
              })}
            </ul>
          </div>
          <div className="pcm-strategy__accumulators__image pcm-anim-right">
            <div
              ref={accumulatorsImageRef}
              className="pcm-strategy__accumulators__image__wrapper"
            >
              <StaticImage
                alt="nature vacation"
                src="../images/strategy/fishing.jpg"
                layout="fullWidth"
                quality={80}
                formats={["auto", "webp", "avif"]}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="pcm-objectives" className="pcm-strategy__objectives">
        <div className="pcm-strategy__objectives__inner">
          <div className="pcm-strategy__objectives__section">
            <div className="pcm-strategy__objectives__section__text">
              <h2 ref={objectivesHeaderRef}>Design Objectives</h2>
              <p ref={objectivesSubheaderRef}>
                {objectivesSubheader.split(" ").map((word, index) => (
                  <span key={word + index}>
                    <span>{`${word} `}</span>{" "}
                  </span>
                ))}
              </p>
            </div>
            <Objectives id={1} data={objectives} />
          </div>
          <div className="pcm-strategy__objectives__section">
            <div className="pcm-strategy__objectives__section__text">
              <h2 ref={constructionHeaderRef}>
                Portfolio Construction Implication
              </h2>
              <p ref={constructionSubheaderRef}>
                {constructionSubheader.split(" ").map((word, index) => (
                  <span key={word + index}>
                    <span>{`${word} `}</span>{" "}
                  </span>
                ))}
              </p>
            </div>
            <Objectives id={2} data={construction} />
          </div>
        </div>
      </section>
      <section className="pcm-strategy__single-text">
        <div
          ref={singleStrategyRef}
          className="pcm-strategy__single-text__inner"
        >
          <div
            id="pcm-strategy__predictability"
            className="pcm-strategy__single-text__section"
          >
            <div className="pcm-strategy__single-text__section__text pcm-anim-left">
              <h5>Invest with Confidence</h5>
              <h2>Predictability of Income</h2>
              <p>
                With one year Treasury Notes and three year Bank CDs paying 5%,
                the need for a more complicated income strategy may not be as
                obvious as when yields on such instruments are low, but when the
                CD or Treasury note matures, investors will need to reinvest the
                maturing principal at whatever the prevailing rate is at the
                time which may be much lower than rates available today.
              </p>
            </div>
            <div className="pcm-strategy__single-text__image pcm-anim-right">
              <div className="pcm-strategy__single-text__image__wrapper">
                <StaticImage
                  alt="predictability"
                  src="../images/strategy/advisor.jpg"
                  layout="fullWidth"
                  quality={80}
                  formats={["auto", "webp", "avif"]}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          </div>
          <div
            id="pcm-strategy__peace"
            className="pcm-strategy__single-text__section"
          >
            <div className="pcm-strategy__single-text__section__text pcm-anim-right">
              <h5>Balancing High Yields and Stability</h5>
              <h2>Peace of mind</h2>
              <p>
                Certain high yielding investments such as Master Limited
                Partnerships (MLPs) and Real Estate Investment Trusts (REITs)
                and pure covered call strategies such as QYLD can offer high
                yields but also come with the potential for steep declines in
                value, large enough to cause many investors to feel they need to
                sell their holdings in order to avoid further damage to their
                retirement savings. These declines tend to coincide with large
                downturns in the stock market when the media is highlighting
                losses and speculating that further losses may be expected. As a
                result, these declines in value can at best create anxiety for
                retired investors who don’t want to be worrying about their
                financial security or about when to sell and when they should be
                enjoying themselves and their families and at worst can prompt
                nervous investors to liquidate their investments, locking in
                losses and remaining in cash until the prices have returned to
                their previous, virtually guaranteeing that their retirement
                savings will never recover.
              </p>
            </div>
            <div className="pcm-strategy__single-text__image pcm-anim-left">
              <div className="pcm-strategy__single-text__image__wrapper">
                <StaticImage
                  alt="peace"
                  src="../images/strategy/yoga.jpg"
                  layout="fullWidth"
                  quality={80}
                  formats={["auto", "webp", "avif"]}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pcm-strategy__multi-text">
        <div ref={multiStrategyRef} className="pcm-strategy__multi-text__inner">
          <div
            id="pcm-strategy__keeping-pace"
            className="pcm-strategy__multi-text__section"
          >
            <div className="pcm-strategy__multi-text__section__text pcm-anim-left">
              <h5></h5>
              <h2>Keeping Pace With Inflation</h2>
              <p>
                Strategies for Retirement Savings in an Inflationary
                Environment.
              </p>
              <ul>
                {keepingPace.text.map((li, index) => (
                  <li key={li + index}>{li}</li>
                ))}
              </ul>
            </div>
            <div className="pcm-strategy__multi-text__image pcm-anim-right">
              <div className="pcm-strategy__multi-text__image__wrapper">
                <StaticImage
                  alt="peace"
                  src="../images/strategy/keepingPace2.jpg"
                  layout="fullWidth"
                  quality={80}
                  formats={["auto", "webp", "avif"]}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          </div>
          <div
            id="pcm-strategy__control"
            className="pcm-strategy__multi-text__section"
          >
            <div className="pcm-strategy__multi-text__section__text pcm-anim-right">
              <h5></h5>
              <h2>Control and flexibility</h2>
              <p>
                Choosing a Path to Retirement Income: Fixed Index Annuities vs.
                PCM Approach.
              </p>
              <ul>
                {control.text.map((li, index) => (
                  <li key={li + index}>{li}</li>
                ))}
              </ul>
            </div>
            <div className="pcm-strategy__multi-text__image pcm-anim-left">
              <div className="pcm-strategy__multi-text__image__wrapper">
                <StaticImage
                  alt="peace"
                  src="../images/strategy/wine.jpg"
                  layout="fullWidth"
                  quality={80}
                  formats={["auto", "webp", "avif"]}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pcm-strategy__cta">
        <div className="pcm-strategy__cta__inner">
          <h2>Learn more about us.</h2>
          <p>
            Preservation Capital was started by two successful veterans of the
            Investment Management industry.
          </p>
          <CTA to="/about" theme="green">
            About us
          </CTA>
        </div>
      </section>
    </div>
  );
};

export default Strategy;
