import React, { useState, useEffect } from "react";
import "./objectives.scss";

interface CounterProps {
  target: number;
  startCount: boolean;
}

const Counter: React.FC<CounterProps> = ({ target, startCount }) => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (!startCount) return;

    let frameId: number;
    let startTime: number | undefined;

    const countUp = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const currentCount = Math.min(
        Math.floor(progress / (1000 / target)),
        target
      );

      setCount(currentCount);

      if (currentCount < target) {
        frameId = requestAnimationFrame(countUp);
      }
    };

    frameId = requestAnimationFrame(countUp);

    return () => {
      cancelAnimationFrame(frameId);
    };
  }, [target, startCount]);

  return <h1 className="pcm-objectives__counter__number">{count}%</h1>;
};

export default Counter;
